<template>
  <v-card>
    <v-toolbar style="background-color: #1daf80; color: #fff">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span class="headline">Termos e Contrato Televet Play</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <div class="align-self-center">
          <v-btn
            raised
            :dark="form"
            :disabled="!form"
            color="#106549"
            @click="postInfoTutor"
          >
            Salvar
          </v-btn>
        </div>
      </v-toolbar-items>
    </v-toolbar>
    <div class="container">
      <v-tabs
        v-model="tab"
        active-class="tab-ativa"
        background-color="transpatrent"
        color="white"
      >
        <v-tab :key="'termos'">
          Termos de Uso
        </v-tab>
        <v-tab :key="'contrato'">
          Contrato Televet Play
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item :key="'termos'">
          <div class="row">
            <div class="col-12">
              <div
                class="my-6 overflow-termos box-shadow"
                elevation="0"
                id="scroll-target"
              >
                <termos-responsabilidade
                  v-scroll:#scroll-target="onScrollTermos"
                />
              </div>
              <v-container>
                <v-checkbox
                  class=" font-weight-bold"
                  v-model="termosCheck"
                  :disabled="!termosValidos"
                >
                  <template v-slot:label>
                    <div
                      class="check-style"
                      :class="termosValidos ? '' : 'text-disable'"
                    >
                      Li e aceito os termos acima.
                    </div>
                  </template>
                </v-checkbox>
              </v-container>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item :key="'contrato'">
          <div class="row">
            <div class="col-12">
              <div
                class="my-6 overflow-termos box-shadow"
                elevation="0"
                id="scroll-target"
              >
                <contrato-televet v-scroll:#scroll-target="onScrollContrato" />
              </div>
              <v-container>
                <v-checkbox
                  class=" font-weight-bold"
                  v-model="contratoCheck"
                  :disabled="!contratoValidos"
                >
                  <template v-slot:label>
                    <div
                      class="check-style"
                      :class="contratoValidos ? '' : 'text-disable'"
                    >
                      Li e aceito os termos acima.
                    </div>
                  </template>
                </v-checkbox>
              </v-container>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-card>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import TermosResponsabilidade from "@/components/dados_cadastrais/termos_responsabilidade.vue";
import ContratoTelevet from "@/components/dados_cadastrais/contrato_televet_play.vue";
import UsuarioService from "@/services/usuario_service.js";

export default {
  components: {
    TermosResponsabilidade,
    ContratoTelevet,
  },
  mixins: [Mixin],
  props: {
    infosUsuario: {
      type: Object,
      // required: true,
      required: false,
    },
  },
  data() {
    return {
      termosCheck: false,
      contratoCheck: false,
      termosValidos: true,
      contratoValidos: true,
      form: false,
      tab: null,
      idUsuario: JSON.parse(sessionStorage.vuex).usuario.id,
      // Services
      services: {
        usuarioService: new UsuarioService(),
      },
    };
  },
  computed: {},
  watch: {
    contratoCheck() {
      this.validarForm();
    },
    termosCheck() {
      this.validarForm();
    },
  },
  mounted() {
    this.getInfoUsuario();
  },
  methods: {
    getInfoUsuario() {
      this.services.usuarioService
        .getAceiteTermo(JSON.parse(sessionStorage.vuex).usuario.dados.clienteId)
        .then(async (response) => {
          const { data } = await response.json();
          if (data[0].aceite_termo == 1) {
            this.termosCheck = true;
            this.contratoCheck = true;
          } else {
            this.termosCheck = false;
            this.contratoCheck = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    postInfoTutor() {
      this.services.usuarioService.postAceiteTermo(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.$_ACTIONS_showSnackbarMessage({
                message:
                  body.message || this.$global.messages.internalServerError,
                color: "sucess",
              });
              this.$emit("close");
            } else {
              this.$_ACTIONS_showSnackbarMessage({
                message:
                  body.message || this.$global.messages.internalServerError,
                color: "error",
              });
            }
          },
          onError: (message) => {
            this.$_ACTIONS_showSnackbarMessage({
              message: message || this.$global.messages.internalServerError,
              color: "error",
            });
          },
          onEnd: () => {},
        },
        {}
      );
    },
    validarForm() {
      if (this.termosCheck && this.contratoCheck) {
        this.form = true;
      } else {
        this.form = false;
      }
    },
  },
};
</script>

<style scoped>
.box-shadow {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  width: 98%;
  margin: auto;
}
.overflow-termos {
  max-height: 450px;
  overflow: auto;
}
.v-label {
  display: contents !important;
}
.text-disable {
  color: gray;
}
.tab-ativa {
  background-color: rgb(29, 175, 128);
}
</style>
