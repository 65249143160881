<template>
  <div>
    <v-card min-height="100vh">
      <v-toolbar style="background-color: #1daf80; color: #fff">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="headline">Receita</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-select
            v-model="convenioSelecionado"
            :items="convenios"
            label="Convênio"
            class="mb-2 ml-1 col-7"
          >
          </v-select>
          <!-- <v-text-field
            v-model="titulo"
            class="col-10"
            label="Titulo"
            placeholder="Pré-operatório"
          ></v-text-field>
          <div class="text-left">
            <textarea
              v-model="receitaTexto"
              class="col-10 ml-3 border "
              placeholder="Receita"
              rows="2"
              maxlength="300"
            ></textarea>
          </div> -->
          <vue-editor v-model="content"></vue-editor>
          <br />
          <!-- <button
            class="btn btn-app-primary btn-padding font-weight-bold"
            @click="add()"
          >
            Adicionar
          </button> -->
          <div class="d-flex flex-column" v-if="receita.length > 0">
            <u v-for="(item, i) in receita" :key="i">
              <li class="d-flex flex-column resp">
                <h4>{{ item.titulo }}</h4>
                <div class="d-flex align-items-center justify-center">
                  <v-textarea
                    class="col-10"
                    rows="2"
                    v-model="item.receitaTexto"
                  ></v-textarea>
                  <!-- <b>{{ i + 1 }}-</b>  -->
                  <v-btn @click="remover_item(i)" class="ml-3" small icon
                    ><v-hover v-slot="{ hover }"
                      ><v-icon medium :color="hover ? '#F44336' : 'gray'">
                        fas fa-times</v-icon
                      ></v-hover
                    ></v-btn
                  >
                  <!-- {{ item.posologia }} -->
                  <!-- <v-btn
                    @click="handleEditReceitaItem(item)"
                    class="ml-3"
                    small
                    icon
                  >
                    <v-hover v-slot="{ hover }">
                      <v-icon medium :color="hover ? 'green' : 'gray'">
                        mdi-pencil-outline
                      </v-icon>
                    </v-hover>
                  </v-btn> -->
                </div>
              </li>
            </u>
          </div>
        </v-container>
        <div class="row mt-5">
          <div class="col-md-12 text-right d-flex justify-lg-space-between">
            <button
              class="btn btn-cancelar btn-padding font-weight-bold text-gray"
              @click="$emit('close')"
            >
              Cancelar
            </button>
            <button class="btn-style-green " @click="gerarPdf()">
              <span> Gerar PDF </span>
            </button>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import MedicamentoService from "@/services/medicamento_service.js";
import VuexSnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";
import { VueEditor } from "vue2-editor";

export default {
  mixins: [VuexSnackbarMixin],
  components: {
    VueEditor,
  },
  beforeMount() {
    // this.loadMedicamentos();
  },
  data: () => ({
    receita: [],
    receitaTexto: "",
    med: {},
    isLoading: false,
    medicamentos: [],
    medicamento: "",
    qntd: "",
    dosagem: "",
    titulo: "",
    convenios: ["Público", "Particular"],
    convenioSelecionado: "",
    editReceitaItem: [],
    content: "",
  }),
  watch: {
    med() {
      this.receita_fill();
    },
    medicamento(e) {
      this.medicamento = e;
    },
    qntd(e) {
      this.qntd = e;
    },
    dosagem(e) {
      this.dosagem = e;
    },
    // editReceitaItem() {
    //   console.log(this.editReceitaItem);
    // },
  },
  methods: {
    // handleEditReceitaItem(item) {
    //   this.editReceitaItem.push(item);
    // },
    remover_item(i) {
      this.receita.splice(i, 1);
    },
    receita_fill() {
      this.dosagem = this.med.posologia;
      this.qntd = this.med.quantidade;
      this.medicamento = this.med.nome;
    },
    loadMedicamentos() {
      const medicamentoService = new MedicamentoService();
      this.isLoading = true;
      medicamentoService.getList({
        onSucess: (status) => (body) => {
          if (status === 200) {
            this.medicamentos = body.data.medicamentos;
          }
        },
        onError: (error) => {
          console.log(error);
        },
        onEnd: () => {
          this.isLoading = false;
        },
      });
    },
    add() {
      if (this.receita.length < 5) {
        this.receita.push({
          titulo: this.titulo,
          receitaTexto: this.receitaTexto,
        });
        this.titulo = "";
        this.receitaTexto = "";
      } else {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Quantidade Excedida. Crie outra outra receita.",
          color: "error",
        });
      }
    },
    gerarPdf() {
      // if (this.receita == "") {
      //   console.log("Receita é obrigatória");
      //   return;
      // }
      this.$emit("gerarPDF", {
        doc: "receita",
        receita: this.content,
        convenio: this.convenioSelecionado
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase(),
      });
      this.receita = [];
      this.titulo = "";
      this.medicamento = "";
      this.qntd = "";
      this.dosagem = "";
    },
  },
};
</script>
<style scoped>
.btn-style-green {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #1daf80 !important;
  height: 30px !important;
  font-size: 12px;
  padding: 10px !important;
  border-radius: 50px;
  margin: 20px 10px 20px 0px;
}
</style>
