import { global } from "@/config/constants";
import vuex from "@/store/index.js";

export default class HTTPService {
  static builder = class {
    build() {}
  };

  baseURL(args) {
    return `${global.api.baseURL}/${args}`;
  }

  baseURLVetus(args) {
    return `${global.api.baseURLVetus}/${args}`;
  }

  baseURLK2(args) {
    return args != ""
      ? `${global.api.baseURLK2}/${args}`
      : `${global.api.baseURLK2}`;
  }

  // tratarErroForbidden () {
  //     vuex.dispatch('setUsuario', {});
  //     vuex.dispatch('setToken', '');
  //     vuex.dispatch('showSnackbarMessage', {
  //         message: 'O seu token de acesso expirou. Logue novamente no sistema.',
  //         color: 'error'
  //     });
  //     router.replace('login');
  // }

  encodeQueryParams(params) {
    let uri = "";
    const paramsNames = Object.keys(params);
    for (let i = 0, len = paramsNames.length; i < len; i++) {
      let paramName = paramsNames[i];
      let paramValue = params[paramName];
      let separator = i === 0 ? "?" : "&";
      uri += `${separator}${paramName}=${paramValue}`;
    }
    return encodeURI(uri);
  }

  request(
    { onSucess, onError, onEnd },
    method,
    resource,
    bodyParams,
    queryParamsObj
  ) {
    if (typeof onSucess !== "function") {
      throw new Error("onSucess is not a function.");
    }
    if (typeof onError !== "function") {
      throw new Error("onError is not a function.");
    }
    if (typeof onEnd !== "function") {
      throw new Error("onEnd is not a function.");
    }
    const headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    if (vuex.getters.getToken) {
      headers.append("Authorization", `Bearer ${vuex.getters.getToken}`);
    }
    let url = "";
    if (method === "GET") {
      const queryParams = queryParamsObj
        ? this.encodeQueryParams(queryParamsObj)
        : "";
      url = this.baseURL(resource + queryParams);
    } else {
      url = this.baseURL(resource);
    }
    const redirect = "follow";
    const config = { method, redirect };
    if (["POST", "GET", "DELETE"].includes(method)) {
      config.headers = headers;
    }
    if (
      method === "POST" &&
      typeof bodyParams === "object" &&
      JSON.stringify(bodyParams) !== "{}"
    ) {
      const urlencoded = new URLSearchParams();
      Object.keys(bodyParams).forEach((campo) => {
        if (bodyParams[campo] instanceof Object) {
          const objParam = bodyParams[campo];
          Object.keys(objParam).forEach((nameParam) => {
            urlencoded.append(`${campo}[${nameParam}]`, objParam[nameParam]);
          });
        } else {
          urlencoded.append(campo, bodyParams[campo]);
        }
      });
      config.body = urlencoded;
    }
    let request = null;
    try {
      request = fetch(url, config);
    } catch (error) {
      request = new Error(error);
    }
    if (request instanceof Error) {
      onError();
    } else {
      request
        .then((response) => {
          const { status } = response;
          // if (status === 403) {
          //     this.tratarErroForbidden();
          //     return;
          // }
          response
            .json()
            .then(onSucess ? onSucess(status) : () => {})
            .catch(onError || (() => {}))
            .finally(onEnd || (() => {}));
        })
        .catch(onError || (() => {}))
        .finally(onEnd || (() => {}));
    }
  }
  fileRequest({ onSucess, onError, onEnd }, method, resource, formData) {
    if (typeof onSucess !== "function") {
      throw new Error("onSucess is not a function.");
    }
    if (typeof onError !== "function") {
      throw new Error("onError is not a function.");
    }
    if (typeof onEnd !== "function") {
      throw new Error("onEnd is not a function.");
    }
    const headers = new Headers();
    // headers.append("Content-type","multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2));
    if (vuex.getters.getToken) {
      headers.append("Authorization", `Bearer ${vuex.getters.getToken}`);
    }
    let url = "";
    url = this.baseURL(resource);
    const redirect = "follow";
    const config = { method, redirect };
    config.headers = headers;
    config.body = formData;
    let request = null;
    try {
      request = fetch(url, config);
    } catch (error) {
      request = new Error(error);
    }
    if (request instanceof Error) {
      onError();
    } else {
      request
        .then((response) => {
          const { status } = response;
          // if (status === 403) {
          //     this.tratarErroForbidden();
          //     return;
          // }
          response
            .json()
            .then(onSucess ? onSucess(status) : () => {})
            .catch(onError || (() => {}))
            .finally(onEnd || (() => {}));
        })
        .catch(onError || (() => {}))
        .finally(onEnd || (() => {}));
    }
  }
  jsonRequest(
    { onSucess, onError, onEnd },
    method,
    resource,
    bodyParams,
    queryParamsObj
  ) {
    if (typeof onSucess !== "function") {
      throw new Error("onSucess is not a function.");
    }
    if (typeof onError !== "function") {
      throw new Error("onError is not a function.");
    }
    if (typeof onEnd !== "function") {
      throw new Error("onEnd is not a function.");
    }
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    if (vuex.getters.getToken) {
      headers.append("Authorization", `Bearer ${vuex.getters.getToken}`);
    }
    let url = "";
    if (method === "GET") {
      const queryParams = queryParamsObj
        ? this.encodeQueryParams(queryParamsObj)
        : "";
      url = this.baseURL(resource + queryParams);
    } else {
      url = this.baseURL(resource);
    }
    const redirect = "follow";
    const config = { method, redirect };
    if (["POST", "GET", "DELETE"].includes(method)) {
      config.headers = headers;
    }
    if (
      method === "POST" &&
      typeof bodyParams === "object" &&
      JSON.stringify(bodyParams) !== "{}"
    ) {
      var raw = JSON.stringify(bodyParams);
      config.body = raw;
    }
    let request = null;
    try {
      request = fetch(url, config);
    } catch (error) {
      request = new Error(error);
    }
    if (request instanceof Error) {
      onError();
    } else {
      request
        .then((response) => {
          const { status } = response;
          // if (status === 403) {
          //     this.tratarErroForbidden();
          //     return;
          // }
          response
            .json()
            .then(onSucess ? onSucess(status) : () => {})
            .catch(onError || (() => {}))
            .finally(onEnd || (() => {}));
        })
        .catch(onError || (() => {}))
        .finally(onEnd || (() => {}));
    }
  }

  get(url, queryStrings = {}) {
    Object.keys(queryStrings)
      .filter((key) => ![null, ""].includes(queryStrings[key]))
      .forEach((key, i) => {
        url += ["?", "&"][+!!i] + `${key}=${encodeURI(queryStrings[key])}`;
      });
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    if (vuex.getters.getToken) {
      headers.append("Authorization", `Bearer ${vuex.getters.getToken}`);
    }
    return fetch(this.baseURL(url), {
      method: "GET",
      redirect: "follow",
      headers,
    });
  }

  post(url, bodyParams = {}) {
    const headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    if (vuex.getters.getToken) {
      headers.append("Authorization", `Bearer ${vuex.getters.getToken}`);
    }
    const urlSearchParams = new URLSearchParams();
    Object.keys(bodyParams).forEach((campo) => {
      if (bodyParams[campo] instanceof Object) {
        const objParam = bodyParams[campo];
        Object.keys(objParam).forEach((nameParam) => {
          urlSearchParams.append(`${campo}[${nameParam}]`, objParam[nameParam]);
        });
      } else {
        urlSearchParams.append(campo, bodyParams[campo]);
      }
    });
    const config = {
      method: "POST",
      redirect: "follow",
      headers,
      body: urlSearchParams,
    };
    return fetch(this.baseURL(url), config);
  }

  postPagSeguro() {
    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer B152E5B2DDBE46E6837995BAE27DE8DD");
    //    headers.append("Authorization", `Bearer ${global.api.pageBank_key}`);

    var raw = JSON.stringify({
      type: "Card",
    });

    var requestOptions = {
      method: "POST",
      headers: headers,
      body: raw,
      redirect: "follow",
    };

    return fetch(
      "https://sandbox.api.pagseguro.com/public-keys",
      requestOptions
    );
  }

  jsonRequestPagSeguro(
    { onSucess, onError, onEnd },
    method,
    resource,
    bodyParams,
    queryParamsObj
  ) {
    if (typeof onSucess !== "function") {
      throw new Error("onSucess is not a function.");
    }
    if (typeof onError !== "function") {
      throw new Error("onError is not a function.");
    }
    if (typeof onEnd !== "function") {
      throw new Error("onEnd is not a function.");
    }
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${global.api.pageBank_key}`);
    let url = "";
    if (method === "GET") {
      const queryParams = queryParamsObj
        ? this.encodeQueryParams(queryParamsObj)
        : "";
      url = this.baseURL(resource + queryParams);
    } else {
      url = "https://sandbox.api.pagseguro.com/public-keys";
    }
    const redirect = "follow";
    const config = { method, redirect };
    if (["POST", "GET", "DELETE"].includes(method)) {
      config.headers = headers;
    }
    if (
      method === "POST" &&
      typeof bodyParams === "object" &&
      JSON.stringify(bodyParams) !== "{}"
    ) {
      var raw = JSON.stringify(bodyParams);
      config.body = raw;
    }
    let request = null;
    try {
      request = fetch(url, config);
    } catch (error) {
      request = new Error(error);
    }
    if (request instanceof Error) {
      onError();
    } else {
      request
        .then((response) => {
          const { status } = response;
          // if (status === 403) {
          //     this.tratarErroForbidden();
          //     return;
          // }
          response
            .json()
            .then(onSucess ? onSucess(status) : () => {})
            .catch(onError || (() => {}))
            .finally(onEnd || (() => {}));
        })
        .catch(onError || (() => {}))
        .finally(onEnd || (() => {}));
    }
  }

  postK2Web(url, bodyParams = {}) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("cache-control", "no-cache");
    headers.append("TOKEN", `${global.tokenK2}`);
    var raw = JSON.stringify(bodyParams);
    const body = raw;
    const config = {
      method: "POST",
      headers,
      body: body,
    };
    return fetch(this.baseURLK2(url), config);
  }
}
