<template>
  <v-card>
    <v-toolbar style="background-color: #1daf80; color: #fff">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span class="headline">Nova Consulta</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items> </v-toolbar-items>
    </v-toolbar>
    <div class="row justify-content-center mt-5"></div>
    <div class="row justify-content-center mt-5">
      <div class="col-lg-10 form-group clearfix mt-2 mb-0 d-flex">
        <v-select
          style="padding-top: 0 !important"
          :items="pets"
          item-text="nome_pet"
          item-value="id_pet"
          v-model="petId"
          label="Selcione o Pet"
        />
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-10 form-group clearfix mb-0">
        <v-select
          style="padding-top: 0 !important"
          :items="procedimentos"
          v-model="procedimentoId"
          label="Selcione o Procedimento"
        />
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-10 form-group clearfix mb-0">
        <v-textarea
          label="Observação"
          v-model="observacao"
          placeholder=" "
          rows="3"
        ></v-textarea>
      </div>
    </div>

    <v-card-actions>
      <div class="row justify-content-center">
        <div class="col-10 text-end">
          <v-btn
            class="mb-2"
            outlined
            color="#1daf80"
            :disabled="validarCadastro"
            @click="cadastarConsulta"
          >
            Continuar
          </v-btn>
        </div>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import PacienteService from "@/services/paciente_service.js";
import PetsService from "@/services/pets_service.js";
import AgendaEspecialidadeService from "@/services/agendamento_especialidade_recepcao_service.js";
import VuexSnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";

export default {
  mixins: [VuexSnackbarMixin],
  components: {},
  props: {
    horarioProp: Object,
  },
  data: () => ({
    loadingInicial: false,
    idCliente: 0,
    clientes: [],
    petId: 0,
    pets: [],
    pet_selecionado: "",
    especialidadeId: 0,
    procedimentoId: 0,
    procedimentos: [],
    dialogCadastrarPet: false,
    dialogCadastroTutor: false,
    validarCadastro: true,
    search: "",
    observacao: "",
    horarioId: 0,
    infos: {},
    services: {
      pacienteService: new PacienteService(),
      petService: PetsService.build(),
      agendaEspecialidadeService: AgendaEspecialidadeService.build(),
    },
    pesquisaTutor: 1,
  }),
  mounted() {
    this.especialidadeId = this.horarioProp.id_especialidade;
    this.unidadeId = this.horarioProp.id_unidade;
    this.unidadeId = this.horarioProp.id_unidade;
    this.horarioId = this.horarioProp.id;
    this.getProcedimentos();
    this.getPetsById();
  },
  computed: {
    allowCadastrarBtn() {
      return 1;
    },
    allowCadastrarTutorBtn() {
      return true;
    },
  },
  watch: {
    procedimentoId() {
      this.validarform();
    },
  },
  methods: {
    closeDialogCadastrarTutor() {
      this.dialogCadastroTutor = false;
    },
    closeDialogCadastrarPet() {
      this.dialogCadastrarPet = false;
    },
    validarform() {
      if (this.pet_selecionado != undefined && this.procedimentoId != 0) {
        this.validarCadastro = false;
      }
    },
    async getPetsById() {
      this.pets = [];
      await this.services.petService
        .getPetsByCliente(this.search)
        .then(async (response) => {
          if (response == undefined || response == null) return;
          const { data } = await response.json();
          console.log(data);
          this.pets = data.pets;
        })
        .finally(() => {});
    },
    async getProcedimentos() {
      await this.services.agendaEspecialidadeService
        .getProcesamentobyUnidadeEspecialidade(
          this.unidadeId,
          this.especialidadeId
        )
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          this.procedimentos = data.procedimentos.map(
            ({ id_procedimento, procedimento }) => {
              return {
                text: procedimento,
                value: parseInt(id_procedimento),
              };
            }
          );
        });
    },
    getModel() {
      return {
        id_agenda_especialidade: parseInt(this.horarioId),
        id_procedimento: this.procedimentoId,
        id_pet: this.petId,
        observacao: this.observacao,
      };
    },
    async cadastarConsulta() {
      await this.services.agendaEspecialidadeService
        .postAgendamentoTelevet(this.getModel())
        .then(async (response) => {
          if (response.status !== 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Erro ao agendar consulta",
              color: "error",
            });
            return;
          }
          this.$_ACTIONS_showSnackbarMessage({
            message: "Consulta agendada com sucesso",
            color: "sucess",
          });
          const { data } = await response.json();
          this.$emit("cadastro", {
            id_agendamento: data.id,
            id_pet: parseInt(this.petId),
            nome_pet: this.getNomePet(this.petId),
          });
        });
    },
    fecharLista() {
      this.pets = [];
    },
    enviarValor(item) {
      this.pet_selecionado = item;
    },
    getNomePet(petId) {
      const pet = this.pets.find((findEle) => findEle.id_pet == petId);
      return pet.nome_pet;
    },
  },
};
</script>

<style scoped>
.list-overflow {
  max-height: 300px;
  overflow-y: auto;
}
.border-list {
  box-shadow: 0px 5px 10px #0000001f;
}
</style>
