<template>
  <div class="background-login" id="bg-login">
    <div v-if="!cliente">
      <login />
    </div>
    <div v-else>
      <login-cliente />
    </div>
  </div>
</template>

<script>
import login from "@/components/login/login.vue";
import loginCliente from "@/components/login/login_cliente.vue";
import { global } from "@/config/constants";

export default {
  components: { login, loginCliente },
  data: () => ({
    cliente: global.visaoCliente,
  }),
  mounted() {
    // this.moveBackgroundImage();
  },
  methods: {
    moveBackgroundImage() {
      const element = document.getElementById("bg-login");
      let movementStrength = 25;
      let width = movementStrength / window.innerWidth;
      let height = movementStrength / window.innerHeight;

      element.addEventListener("mousemove", function(e) {
        let pageX = e.pageX - window.innerWidth / 2;
        let pageY = e.pageY - window.innerHeight / 2;

        let newvalueX = width * pageX * -1 - 25;
        let newvalueY = height * pageY * -1 - 50;
        element.style.backgroundPositionX = newvalueX + "px";
        element.style.backgroundPositionY = newvalueY + "px";
      });
    },
  },
};
</script>
<style scoped>
.background-login {
  background-image: url("../assets/images/BG_Pettree.png");
  position: fixed;
  height: 100%;
  width: 100%;
  background-size: cover;
}
</style>
