import HTTPService from "@/services/http_service.js";

export default class PagamentoService extends HTTPService {
  resource = "loja/compra/pagsegurocartao";

  static build() {
    return new PagamentoService();
  }
  /**
        @returns {Promise<Response>}
     */

  postPagamento(responseFunctions, model) {
    this.jsonRequest(responseFunctions, "POST", `${this.resource}/new`, model);
  }

  postPublicKey() {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer B152E5B2DDBE46E6837995BAE27DE8DD"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    return fetch(
      "https://sandbox.api.pagseguro.com/public-keys/card",
      requestOptions
    );
  }

  async postPublicKeyPag(responseFunctions) {
    this.jsonRequestPagSeguro(responseFunctions, "POST", "", {
      type: "Card",
    });
  }
}
