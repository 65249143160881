var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{staticStyle:{"background-color":"#1daf80","color":"#fff"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_c('span',{staticClass:"headline"},[_vm._v("Modalidade de Consulta")])]),_c('v-spacer'),_c('v-toolbar-items')],1),_c('v-container',[_vm._v(" "+_vm._s(_vm.modalidadeSelecionada)+" "),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-10"},[_c('v-card',{staticClass:"my-5 py-3 px-4",class:!_vm.modalidadeMedCar
                ? 'card-disabled'
                : _vm.modalidadeSelecionada == 'MedicinaCarinho'
                ? ' card-med-selected'
                : 'card-med',attrs:{"elevation":"2","outlined":"","disabled":!_vm.modalidadeMedCar},on:{"click":function($event){return _vm.selecionarModalidade('MedicinaCarinho')}}},[_c('h4',{staticClass:"text-start mb-3"},[_vm._v(" Medicina com Carinho ")]),_c('hr'),_c('div',{staticClass:"row justify-content-between"},[_c('div',{staticClass:"col-4 text-start pl-4"},[_c('span',{staticClass:"text-start"},[_vm._v("Pet: "+_vm._s(_vm.medicinaCarinho.petNome ? _vm.medicinaCarinho.petNome : "-"))])]),_c('div',{staticClass:"col-4 text-start pl-4"},[_c('span',{staticClass:"text-start"},[_vm._v("Número Cartão: "+_vm._s(_vm.medicinaCarinho.numCard ? _vm.medicinaCarinho.numCard : "-"))])]),_c('div',{staticClass:"col-4 text-start pl-4"},[_c('span',{staticClass:"text-start"},[_vm._v("Cartão "+_vm._s(_vm.medicinaCarinho.telemedicina == "S" ? "Válido" : "Inválido"))])])])]),_c('v-card',{staticClass:"my-5 py-3 px-4",class:!_vm.modalidadeVoucherAnual
                ? 'card-disabled'
                : _vm.modalidadeSelecionada == 'Anual'
                ? 'card-med-selected'
                : 'card-med',attrs:{"elevation":"2","outlined":"","disabled":!_vm.modalidadeVoucherAnual},on:{"click":function($event){return _vm.selecionarModalidade('Anual')}}},[_c('h4',{staticClass:"text-start mb-3"},[_vm._v(" Atendimento Anual ")]),_c('hr'),_c('div',{staticClass:"row justify-content-between"},[_c('div',{staticClass:"col-4 text-start pl-4"},[_c('span',{staticClass:"text-start"},[_vm._v("Pet: "+_vm._s(_vm.anual.petNome ? _vm.anual.petNome : "-"))])]),_c('div',{staticClass:"col-4 text-start pl-4"},[_c('span',{staticClass:"text-start"},[_vm._v("Vencimento: "+_vm._s(_vm.anual.dataVencimento ? _vm.anual.dataVencimento : "-"))])]),_c('div',{staticClass:"col-4 text-start pl-4"},[_c('span',{staticClass:"text-start"},[_vm._v("Modalidade: "+_vm._s(_vm.anual.idVoucher ? "Válido" : "Inválido"))])])])]),_c('v-card',{staticClass:"my-5 py-3 px-4",class:!_vm.modalidadeVoucherAvulso
                ? 'card-disabled'
                : _vm.modalidadeSelecionada == 'Avulso'
                ? ' card-med-selected'
                : 'card-med',attrs:{"elevation":"2","outlined":"","disabled":!_vm.modalidadeVoucherAvulso},on:{"click":function($event){return _vm.selecionarModalidade('Avulso')}}},[_c('h4',{staticClass:"text-start mb-3"},[_vm._v(" Atendimento Avulso ")]),_c('hr'),_c('div',{staticClass:"row justify-content-between"},[_c('div',{staticClass:"col-4 text-start pl-4"},[_c('span',{staticClass:"text-start"},[_vm._v("Pet: "+_vm._s(_vm.avulso.petNome ? _vm.avulso.petNome : "-"))])]),_c('div',{staticClass:"col-4 text-start pl-4"},[_c('span',{staticClass:"text-start"},[_vm._v("Modalidade: "+_vm._s(_vm.avulso.idVoucher ? "Válido" : "Inválido"))])])])])],1)]),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-10 d-flex justify-content-between"},[_c('div',[_c('v-dialog',{attrs:{"fullscreen":"","max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","raised":"","color":"#1daf80"}},'v-btn',attrs,false),on),[_vm._v(" Adquirir ")])]}}]),model:{value:(_vm.dialogPagamento),callback:function ($$v) {_vm.dialogPagamento=$$v},expression:"dialogPagamento"}},[(_vm.dialogPagamento)?_c('modal-pagamento',{attrs:{"infos":_vm.infos},on:{"close":function($event){_vm.dialogPagamento = false},"pago":function($event){return _vm.pago($event)}}}):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"dark":"","raised":"","color":"#1daf80"}},'v-btn',attrs,false),on),[_vm._v(" Informar Medicina com Carinho ")])]}}]),model:{value:(_vm.dialogMedicinaCarinho),callback:function ($$v) {_vm.dialogMedicinaCarinho=$$v},expression:"dialogMedicinaCarinho"}},[(_vm.dialogMedicinaCarinho)?_c('modal-medicina-carinho',{attrs:{"infos":_vm.infos},on:{"close":function($event){_vm.dialogMedicinaCarinho = false},"cadastrado":_vm.cadastrado}}):_vm._e()],1)],1),_c('div',[_c('v-btn',{attrs:{"dark":_vm.modalidadeSelecionada == '' ? false : true,"raised":_vm.modalidadeSelecionada == '' ? false : true,"color":"#1daf80","disabled":_vm.modalidadeSelecionada == '' ? true : false},on:{"click":function($event){return _vm.atender()}}},[_vm._v(" Confirmar ")])],1)])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }