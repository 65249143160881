const urlDev = "https://f4a8-138-121-121-66.ngrok-free.app";

const base = "Homolog";
//const base = "Dev";

// const base = "Prod";
const url = selecionarBase(base);

const global = {
  visaoCliente: false,
  tokenK2:
    "EAAD8Wjfi4VgBO12K8ewaDqCyEeWL6DfFmeblvypIXVEBWDnW2iFrPTGGlh7ZBlHWpKWZCrHWcT8kABq2sytDT8FrAG8cjEua3bXK3DrxWJzeegvE6nlVFLZACwFbNU5eOdkZBeNk1w6JQhgS9vVGmodOv0iZBHtkdDI6MaDfsv0Hlzqw6HB61jFKcMLW7ahB8SXAZA42lPWk7T1T9W",
  api: {
    baseURLK2:
      "https://anclivepa.placein.com.br/app.allowhats_apoio&assunto=sendMessage",
    baseURL: url.baseURL,
    baseURLVetus: url.baseURLVetus,
    baseUrlChamefacil: url.baseUrlChamefacil,
    baseUrlChamefacil_api: url.baseUrlChamefacil_api,
    pageBank_key: url.pageBank_key,
  },
  consultas: {
    tempoMinimo: 1,
    idTipoProcedimentoConsulta: 5,
  },
  tiposProcedimentos: {
    //consulta: 5,
    //cirurgia: 6,
    consulta: 2,
    cirurgia: 3,
    compromissoPessoal: 7,
  },
  utils: {
    diasDaSemana: [
      { value: 0, label: "Domingo" },
      { value: 1, label: "Segunda-feira" },
      { value: 2, label: "Terça-feira" },
      { value: 3, label: "Quarta-feira" },
      { value: 4, label: "Quinta-feira" },
      { value: 5, label: "Sexta-feira" },
      { value: 6, label: "Sábado" },
    ],
  },
  profiles: [
    { id: 1, description: "admin" },
    { id: 2, description: "medico" },
    { id: 3, description: "assistente" },
    { id: 4, description: "paciente" },
    { id: 56, description: "recepcao" },
  ],
  snackbar: {
    colors: {
      sucess: "#3E682A",
      error: "error",
      warning: "#FFCA28",
    },
  },
  messages: {
    internalServerError:
      "Ocorreu um erro durante o processamento da sua solicitação. Tente novamente dentro de alguns minutos. Se o serviço continuar sem funcionar, contate o administrador do sistema.",
  },
};

function selecionarBase(base) {
  let baseURL = urlDev + "/anclivepa_pv_w/public";
  let baseURLVetus = urlDev + "/anclivepa_legacy/api";

  let baseUrlChamefacil = "http://chamefacilpettreeteste.prolins.com.br/api";
  let baseUrlChamefacil_api =
    "http://chamefacilpettreeapiteste.prolins.com.br/api";

  let pageBank_key = "B152E5B2DDBE46E6837995BAE27DE8DD";

  switch (base) {
    case "Prod":
      // Api Pettree
      baseURL = "https://api.anclivepa-pv.pettree.com.br/v1/public";
      baseURLVetus = "https://api.anclivepa-pv.pettree.com.br/vet1/public/api";

      // Api ChameFacil
      baseUrlChamefacil_api =
        "https://chamefacilpettreeapi2.prolins.com.br/api";
      baseUrlChamefacil = "https://chamefacilpettree.prolins.com.br/api";

      // Chave PagBank
      pageBank_key =
        "c08dbe4c-232f-4759-a4ed-ff2db7f4a335bc2bcdc74aa881d4d582845a0064255f5a91-417e-498f-b839-e01db87a9e86";
      break;

    case "Homolog":
      // Api Pettree
      baseURL = "https://pettreeapi.pv.homolog.prolins.com.br/v1/public";
      baseURLVetus =
        "https://pettreeapi.pv.homolog.prolins.com.br/vet1/public/api";
      break;
  }

  return {
    baseURL,
    baseURLVetus,
    baseUrlChamefacil,
    baseUrlChamefacil_api,
    pageBank_key,
  };
}
export { global };

// Produção
// baseURL: "https://api.anclivepa-pv.pettree.com.br/v1/public",
// baseUrlChamefacil_api: "https://chamefacilpettreeapi2.prolins.com.br/api",
// baseUrlChamefacil: "https://chamefacilpettree.prolins.com.br/api",
// pageBank_key:
//   "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAr+ZqgD892U9/HXsa7XqBZUayPquAfh9xx4iwUbTSUAvTlmiXFQNTp0Bvt/5vK2FhMj39qSv1zi2OuBjvW38q1E374nzx6NNBL5JosV0+SDINTlCG0cmigHuBOyWzYmjgca+mtQu4WczCaApNaSuVqgb8u7Bd9GCOL4YJotvV5+81frlSwQXralhwRzGhj/A57CGPgGKiuPT+AOGmykIGEZsSD9RKkyoKIoc0OS8CPIzdBOtTQCIwrLn2FxI83Clcg55W8gkFSOS6rWNbG5qFZWMll6yl02HtunalHmUlRUL66YeGXdMDC2PuRcmZbGO5a/2tbVppW6mfSWG3NPRpgwIDAQAB",

// Homologção
// baseURL: "https://pettreeapi.pv.homolog.prolins.com.br/v1/public",
// baseURLVetus:
//   "https://pettreeapi.pv.homolog.prolins.com.br/vet1/public/api",

// Desenvolvimento
// baseURL:
//   "https://b7e6-177-190-208-209.ngrok-free.app/anclivepa_pv_w/public",
// baseURLVetus:
//   "https://b7e6-177-190-208-209.ngrok-free.app/anclivepa_legacy/api",

// baseUrlChamefacil: "http://chamefacilpettreeteste.prolins.com.br/api",
// baseUrlChamefacil_api:
//   "http://chamefacilpettreeapiteste.prolins.com.br/api",
// pageBank_key:
//   "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAr+ZqgD892U9/HXsa7XqBZUayPquAfh9xx4iwUbTSUAvTlmiXFQNTp0Bvt/5vK2FhMj39qSv1zi2OuBjvW38q1E374nzx6NNBL5JosV0+SDINTlCG0cmigHuBOyWzYmjgca+mtQu4WczCaApNaSuVqgb8u7Bd9GCOL4YJotvV5+81frlSwQXralhwRzGhj/A57CGPgGKiuPT+AOGmykIGEZsSD9RKkyoKIoc0OS8CPIzdBOtTQCIwrLn2FxI83Clcg55W8gkFSOS6rWNbG5qFZWMll6yl02HtunalHmUlRUL66YeGXdMDC2PuRcmZbGO5a/2tbVppW6mfSWG3NPRpgwIDAQAB",
