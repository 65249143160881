<template>
  <div class="row">
    <v-col col="12" class="row col-12 justify-content-center">
      <div class="col-4">
        <v-text-field
          v-model="data_consultas"
          label="Data"
          type="date"
        ></v-text-field>
      </div>
      <div class="col-2">
        <v-btn
          @click="atualizarCompromissos"
          class="mt-4"
          color="green"
          text="white"
          elevation="2"
          >Atualizar</v-btn
        >
      </div>
    </v-col>
    <div class="tabela_agenda_lista" style="width: 100vw">
      <fila-tabela
        v-if="compromissos.length && !loading"
        :compromissos="compromissos"
        :consulta-id="consultaId"
        @iniciar-consulta="iniciarConsulta($event)"
        @paciente-ausente="ativarModalAtrasoPaciente"
        @paciente="paciente = $event"
        @atualizarCompromissos="atualizarCompromissos"
      />

      <v-progress-circular
        indeterminate
        color="green"
        v-if="loading"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import FilaTabela from "./fila_tabela";
import DashboardService from "@/services/dashboard_service";
import VuexSnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";

export default {
  mixins: [VuexSnackbarMixin],
  mounted() {
    this.data_consultas = this.getDateToday();
    this.atualizarCompromissos();
  },
  components: {
    FilaTabela,
  },
  data: () => ({
    modalConsulta: false,
    modalAtrasoPaciente: false,
    paciente: {},
    info: {},
    dashboard: "nova",
    compromissos: [],
    loading: false,
    indexCompromissoSelecionado: null,
    idAgendamentoSelecionado: null,
    data_consultas: null,
    consultaId: 0,
  }),
  methods: {
    atualizarCompromissos() {
      this.loading = true;
      setTimeout(() => {
        this.compromissos = [];
        const dashboardService = new DashboardService();
        dashboardService.getDashboarMedico(
          {
            onSucess: (status) => (body) => {
              if (status === 200) {
                this.compromissos = body.data.map((agenda) => ({
                  idAgendamento: agenda.id_agendamento,
                  idConsulta: agenda.id_consulta,
                  emAtraso: !!agenda.atrasado,
                  data: agenda.data,
                  horarioInicio: agenda.hora_inicio,
                  horarioFinal: agenda.hora_final,
                  nomePaciente: agenda.nome,
                  idPaciente: agenda.paciente_id,
                  idCliente: agenda.id_cliente,
                  procedimento: agenda.procedimento,
                  tipoProcedimento: agenda.tipo_procedimento,
                  idProcedimento: +agenda.id_procedimento,
                  idTipoProcedimento: agenda.id_tipo_procedimento,
                  idPet: agenda.id_pet,
                  idRequisicao: agenda.id_requisicao,
                  nomePet: agenda.nome_pet,
                  id_requisicao: agenda.id_requisicao,
                  status_sala: agenda.status_sala,
                }));
              }
            },
            onError: (error) => {
              console.error(error);
              this.$_ACTIONS_showSnackbarMessage({
                message: this.$global.messages.internalServerError,
                color: "error",
              });
            },
            onEnd: () => {
              this.loading = false;
            },
          },

          ~~JSON.parse(sessionStorage.vuex).usuario.dados.medicoId,
          this.data_consultas
        );
      }, 1000);
    },
    iniciarConsulta(e) {
      console.log(e);
      this.info = e;
      this.modalConsulta = true;
    },
    cancelarConsulta() {
      this.modalConsulta = false;
      this.info = {};
      this.compromissos = [];
      this.atualizarCompromissos();
    },
    finalizarConsulta(e) {
      console.log({ e });
      this.modalConsulta = false;
      this.$_ACTIONS_showSnackbarMessage({
        message: e.msg,
        color: e.cor,
      });
      this.consultaId = e.consultaId;
      // this.$router.replace({ name: "prontuarios", params: { search: e.nome } });
      this.info = {};
      this.compromissos = [];
    },
    ativarModalAtrasoPaciente(event) {
      this.idAgendamentoSelecionado = event.idAgendamentoSelecionado;
      this.modalAtrasoPaciente = true;
    },
    moverPacienteParaListaDeAtrasados() {
      for (let i = 0, len = this.compromissos.length; i < len; i++) {
        let compromisso = this.compromissos[i];
        if (compromisso.idAgendamento === this.idAgendamentoSelecionado) {
          compromisso.emAtraso = true;
          this.idAgendamentoSelecionado = null;
          break;
        }
      }
      this.modalAtrasoPaciente = false;
    },
    removerPacienteDaListaDeAtrasados(event) {
      for (let i = 0, len = this.compromissos.length; i < len; i++) {
        let compromisso = this.compromissos[i];
        if (compromisso.idAgendamento === event.idAgendamento) {
          compromisso.emAtraso = false;
          break;
        }
      }
    },
    getDateToday() {
      const dateNow = new Date();
      return `${dateNow.getUTCFullYear()}-${(dateNow.getUTCMonth() + 1)
        .toString()
        .padStart(2, "0")}-${dateNow
        .getDate()
        .toString()
        .padStart(2, "0")}`;
    },
  },
};
</script>

<style scoped>
.titulo-cards-fila span {
  margin-right: 1rem;
  font-size: 1rem;
}

.titulo-cards-fila hr {
  display: block;
  width: 100%;
  color: #fff;
  margin: 0;
}

/* dashboard nova */
</style>
