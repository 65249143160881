<template>
  <v-card>
    <v-toolbar style="background-color: #1daf80; color: #fff">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span class="headline">Meus Vouchers pet: {{ infos.nome_pet }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items> </v-toolbar-items>
    </v-toolbar>

    <div class="row justify-content-center mt-5 p-5">
      <div class="col-12">
        <h3 class="text-left mb-5">Vouchers</h3>
        <v-data-table
          :headers="headers"
          :items="listaVouchers"
          class="elevation-1"
          :loading="loading"
          v-on:update:sort-by="options.ordenacao = false"
          :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
          :loading-text="'Carregando...'"
        >
          <!-- <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="addProdServLoja(item.id_produto)"
              title="Adicionar"
            >
              fa-plus
            </v-icon>
          </template> -->
        </v-data-table>
      </div>
    </div>
  </v-card>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import VoucherService from "../../services/voucher_service";
import AuthService from "../../services/auth_service";
import ValidationService from "../../services/validation_service";

export default {
  mixins: [Mixin],
  props: {
    infos: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      listaVouchers: [],
      listaStatus: [
        { id: 0, desc: "Todos" },
        { id: 1, desc: "Ativo" },
        { id: 2, desc: "Inativo" },
      ],
      statusSelected: 0,
      services: {
        voucherService: VoucherService.build(),
        authService: AuthService.build(),
        validationService: ValidationService,
      },
      headers: [
        { text: "Pet", value: "pet", sortable: false },
        { text: "Serial", value: "serial", sortable: false },
        { text: "Status", value: "desc_status", sortable: false },
        { text: "Tipo Voucher", value: "desc_tipo", sortable: false },
        { text: "Criação", value: "data_criacao_formatada", sortable: false },
        {
          text: "Vencimento",
          value: "data_vencimento_formatada",
          sortable: false,
        },
        { text: "Unidade", value: "unidade", sortable: false },
        // { text: "Ações", align: "end", value: "actions", sortable: false },
      ],
    };
  },
  watch: {},
  mounted() {
    this.listarVouchers();
  },
  methods: {
    async listarVouchers() {
      this.listaVouchers = [];
      this.loading = true;
      await this.services.voucherService
        .getVoucherbyPetId(this.infos.id_pet)
        .then(async (response) => {
          const { data } = await response.json();
          const vouchers = data.vouchers;
          for (let index = 0; index < vouchers.length; index++) {
            const element = vouchers[index];
            this.listaVouchers.push({
              id: element.id,
              id_tipo: element.id_tipo,
              desc_tipo: element.tipo_voucher.toLowerCase(),
              id_status: element.id_status,
              desc_status: element.voucher_status.toLowerCase(),
              data_criacao: element.data_criacao,
              data_criacao_formatada: this.formatarData(element.data_criacao),
              data_vencimento: element.data_vencimento,
              data_vencimento_formatada: this.formatarData(
                element.data_vencimento,
                false
              ),
              serial: element.serial,
              id_pet: element.id_pet,
              pet: element.pet.toLowerCase(),
              unidade: element.unidade.toLowerCase(),
              id_usuario: element.id_usuario,
              desc_tutor: element.tutor.toLowerCase(),
            });
          }
          this.loading = false;
          this.pesquisado = true;
        })
        .catch((error) => {
          this.loading = false;
          this.pesquisado = true;
          console.log(error);
        });
    },
    formatarData(data_requisicao) {
      if (data_requisicao) {
        const arrData = data_requisicao.split("-");
        return `${arrData[2]}/${arrData[1]}/${arrData[0]}`;
      } else {
        return "Sem Data";
      }
    },
  },
};
</script>

<style scoped>
.form-group label {
  text-align: left !important;
  display: block;
}

.form-group .label-genero {
  margin-right: 1rem;
}
</style>
