<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">
          Televet Play Dashboard
        </h3>
      </div>
    </div>
    <dashboard-medico-telemedicina />
  </div>
</template>

<script>
import dashboardMedicoTelemedicina from "@/components/dashboards/dashboard_medico_telemedicina/index.vue";
import VuexMixin from "@/mixins/vuex_mixin";

export default {
  mixins: [VuexMixin],
  components: {
    dashboardMedicoTelemedicina,
  },
};
</script>
