import { global } from "@/config/constants";
const public_key = global.api.pageBank_key;

const PagBankService = {
  async loadSDK() {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src =
        "https://assets.pagseguro.com.br/checkout-sdk-js/rc/dist/browser/pagseguro.min.js";
      script.async = true;
      script.onload = resolve;
      script.onerror = reject;
      document.head.appendChild(script);
    });
  },
  encryptCardData(cardData) {
    cardData.publicKey = public_key;
    // eslint-disable-next-line no-undef
    return PagSeguro.encryptCard(cardData);
  },
};

export default PagBankService;
